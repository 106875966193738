.WrapperFormContact{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.WrapperFormRegistro{
    width: 100%;
}

.TitleFRegistro{
    color: #295B85;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    padding-right: 4rem;
    padding-bottom: 0.7rem;
    margin: 3rem 4rem;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.ContainerFRegistro{
    width: 65%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.LabelFRegisto{
    width: 75%;
    margin: 1.5rem 0;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}
.InputFRegisto{
    width: 100%;
    height: 2.5rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
}

.InputFRegisto::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFRegisto[type='date']{
    color: #9C9C9C;
    font-family: 'Roboto', sans-serif;
    padding-right: 1rem;
}
.BlockRFRegistro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 37.5%;
}
.LabelRFRegistro{
    width: 50%;
    color: #295B85;
    font-size: 16px;
    font-weight: 500;
}
.RadioFRegistro{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background-color: #EBEBEB;
    border: 5px solid #EBEBEB;
    transition: 0.2s all linear;
    margin-right: 5px;
}
.RadioFRegistro:checked {
    background-color: #1d5481;
}
  
.FormRegistro{
    text-align: center;
}
.ButtonFRegistro{
    margin: 3rem auto;
    color: #ffff;
    background-color: #1d5481;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonFRegistro:hover{
    background-color: #22b601;
}
@media (min-width:900px) {
    .WrapperFormRegistro{
        width: 65%;
    }
}

@media (min-width:550px) {
    .InputFRegisto{
        width: 75%;
    }
    .LabelFRegisto{
        width: 25%;
        text-align: right;
        margin: 1.5rem 0;
    }
}
