.WrapperBRegistro{
    width: 100%;
    height: 400px;
    position: relative;
    background-color: red;
    overflow: hidden;
}
.ImgBRegistro{
    position: absolute;
    z-index: 5;
    max-height:100%;
    min-width:100%;
}
.InfoBRegistro{
    position: absolute;
    min-height:100%;
    width: 100%;
    z-index: 6;
    background-color: #55881ed2;
    /* background-color: #697a57d2; */
    text-align: center;
}
.TitleBRegistro{
    width: 80%;
    font-size: 2.5rem;
    font-weight: 700;
    color: #ffff;
    margin: 5rem auto 3rem ;
}

.TextBRegistro{
    width: 60%;
    font-size: 1.3rem;
    font-weight: 500;
    color: #ffff;
    margin: auto;
    text-align: center;
}

@media (min-width:900px) {
    .WrapperBRegistro{
        width: 35%;
        height: 800px;
    }
    .TitleBRegistro{
        width: 100%;
    }
    .TextBRegistro{
        text-align: left;
    }
    
    
}