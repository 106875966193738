
.ImageBlock{
    width: 100%;
    height: 330px;
    position: relative;
    cursor: pointer;
}

.ImagePro{
    width: 100%;
    height: 330px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
}

.TextContainer{
    width:100%;
    height:100%;
    position: absolute;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap:wrap;
    border: none;
    background-color: rgba(0,0,0,.7);
}

.TextContainer:hover{
    background-color: rgba(0,0,0,.7);
}
.TextContainer:hover p{
    display: block;
}
    
.TextTitle{
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    color: #fff;
    padding-bottom: 0.5rem;
    text-align:center;
    width:100%;
    display: block;
}

.TextDesc{
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    width:100%;
    text-align:center;
    display: block;
}

.DisplayImagesFalse{
    display: none;
    background-color:rgba(38,38,38,.86);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 40;
}

.DisplayImagesTrue{
    background-color:rgba(38,38,38,.86);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 40;
    height: 100%;
}

.DIContainerclose{
    text-align: right;
    margin: 2rem;
    cursor: pointer;
}

.DIClose{
    color: #fff;
    font-size: 30px;
    font-weight: 100;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.DIArrow{
    width: 5%;
    color: #fff;
    font-size: 25px;
    font-weight: 100;
   
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.DIContainerImage{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.DICimage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    text-align: center;
}
.DIimage{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.DIText{
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}

@media (min-width:900px) {
    .DIimage{
        height: 540px;
    } 
}

@media (min-width:500px) {
    .DIimage{
        height: 450px;
    } 
}

@media (min-width:900px) {

    .ImageBlock{
        width: 50%;
    }
    .TextTitle{
        display: none;
    }
    .TextDesc{
        display: none;
    }
    .TextContainer{
        background-color: transparent;
    }
}