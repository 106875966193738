

.App{
    background-color: white;
}
.WrapperHeader{
    background-color: #8bc540;  
    width: 100%;
    height: 90px;
    padding: 1rem 3rem;
    display: flex;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px;
    position: absolute;
    z-index: 10;
}
.GapHeader{
    height: 90px;
}
.ContentLogoHeader{
    width: 40%;
    display: flex;  
}

.ContentMenuHeader{
    width: 60%;
    display: none;
}
.ContentMenuHeaderIcon{
    width: 60%;
    text-align: right;
}
.ContentMenuHeaderIconMini{
    border: none;
    background-color: transparent;
    height: 90px;
    color: #fff;
    font-size: 40px;
    margin: auto;
    padding-bottom: 2rem;
    /* background-color: red; */
}

.OptionHeaderNormal{
    color:  #262626;
    background-color:  transparent;
    height: 80px;
    width: 20%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0.9;
    cursor: pointer; 
    text-decoration: none;
    border: none;
}
    
.OptionHeaderNormal:hover{
    color:  #fff;
    background-color:  #8bc540;
}

.OptionHeaderSelected{
    color:  #fff;
    background-color:  #8bc540;
    height: 80px;
    width: 20%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0.9;
    cursor: pointer; 
    text-decoration: none;
    border: none;
}

.LogoImgHeader{
    width: 230px;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#HeaderLogoColor{
    display: none;
}
#HeaderLogoBlank{
    display: block;
    width: 10rem;
}

#MenuIcon-hide{
    display: none;
}
#MenuIcon-show{
    display: block;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 90px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px;
}
.OptionHeaderMiniNormal{
    width: 100%;
    background-color: #8bc540;
    color: #fff;
    display: block;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    padding: 1rem;
    padding-left: 2rem;
}
.OptionHeaderMiniSelected{
    width: 100%;
    background-color: #fff;
    color: #8bc540;
    display: block;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    padding: 1rem;
    padding-left: 2rem;
}
@media (min-width:1100px) {
    #HeaderLogoColor{
        display: block;
    }
    #HeaderLogoBlank{
        display: none;
    }
    .WrapperHeader{
        background-color: #fff;  ; 
        height: 112px;   
    }
    .GapHeader{
        height: 112px;
    }
    .ContentMenuHeader{
        display: flex;
    }
    .ContentMenuHeaderIcon{
        display: none;
    }
}