.WrapperBFinan{
    color:  #fff;
    background-color:  #8bc540;
    width:100%;
}

.ContainerBFinan1{
    width:100%;
    padding: 2.5rem 2rem;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}
    

.ContainerBFinan2{
    width:100%;
}
    
.TextBFinan1{
    width:100%;
    font-weight: 700;
    font-size: 28px;
    padding-left: 3rem;
    text-align:center;
}
    
.ButtonBFinan{
    background-color: #fff;
    color: #8bc540;
    width:fit-content;
    margin: 2rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 400;
    font-size: 20px;
    text-align:center;
    cursor: pointer;
    text-decoration: none;
}