.WrapperCambioContra{
    background-color:rgba(38,38,38,.86);
    position: fixed;
    z-index: 40;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
}
.FormCambioContra{
    width: 95%;
    background-color: #ffff;
    height: fit-content;
    margin: auto;
    text-align: center;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column  ;
    align-items: center;
}
.InputCambioC{
    width: 80%;
    height: 2.3rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
    margin-bottom: 1em;
}

.InputCambioC::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.LabelCambioC{
    font-size: 1rem;
    font-weight: 700;
    color: #8FC456;
    display: block;
    width: 80%;
    text-align: left;
    margin: auto;
    padding-bottom: 0.3rem;
}
.ButtonCambioC1{
    width: 60%;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 0.4rem;
    color: #ffff;
    background-color: #8FC456;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem 0;
    cursor: pointer;
    text-align: center;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonCambioC1:hover{
    background-color: #8FC456;
}
.ButtonCambioC2{
    width: 45%;
    margin: auto;
    margin-bottom: 1rem;
    color: #c2c2c5;
    border: none;
    background-color: #ffff;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 0;
    cursor: pointer;
    text-align: center;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonCambioC2:hover{
    color: #8FC456;
}

.contentModal{
    border-radius: 1rem;
    width: 80vw;
}

.inputDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 7em;
}

.alertChangePassword{
    color: red;
    font-size: 8pt;
    font-weight: bold;
    text-decoration:underline;
    margin-bottom: 1rem;
}

@media(min-width: 1100px){
    .contentModal{
        border-radius: 1rem;
        width: 35vw;
    }
}