@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);


.App{
    background-color: white;
}
.WrapperHeader{
    background-color: #8bc540;  
    width: 100%;
    height: 90px;
    padding: 1rem 3rem;
    display: flex;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px;
    position: absolute;
    z-index: 10;
}
.GapHeader{
    height: 90px;
}
.ContentLogoHeader{
    width: 40%;
    display: flex;  
}

.ContentMenuHeader{
    width: 60%;
    display: none;
}
.ContentMenuHeaderIcon{
    width: 60%;
    text-align: right;
}
.ContentMenuHeaderIconMini{
    border: none;
    background-color: transparent;
    height: 90px;
    color: #fff;
    font-size: 40px;
    margin: auto;
    padding-bottom: 2rem;
    /* background-color: red; */
}

.OptionHeaderNormal{
    color:  #262626;
    background-color:  transparent;
    height: 80px;
    width: 20%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0.9;
    cursor: pointer; 
    text-decoration: none;
    border: none;
}
    
.OptionHeaderNormal:hover{
    color:  #fff;
    background-color:  #8bc540;
}

.OptionHeaderSelected{
    color:  #fff;
    background-color:  #8bc540;
    height: 80px;
    width: 20%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0.9;
    cursor: pointer; 
    text-decoration: none;
    border: none;
}

.LogoImgHeader{
    width: 230px;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#HeaderLogoColor{
    display: none;
}
#HeaderLogoBlank{
    display: block;
    width: 10rem;
}

#MenuIcon-hide{
    display: none;
}
#MenuIcon-show{
    display: block;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 90px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px;
}
.OptionHeaderMiniNormal{
    width: 100%;
    background-color: #8bc540;
    color: #fff;
    display: block;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    padding: 1rem;
    padding-left: 2rem;
}
.OptionHeaderMiniSelected{
    width: 100%;
    background-color: #fff;
    color: #8bc540;
    display: block;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    padding: 1rem;
    padding-left: 2rem;
}
@media (min-width:1100px) {
    #HeaderLogoColor{
        display: block;
    }
    #HeaderLogoBlank{
        display: none;
    }
    .WrapperHeader{
        background-color: #fff;  ; 
        height: 112px;   
    }
    .GapHeader{
        height: 112px;
    }
    .ContentMenuHeader{
        display: flex;
    }
    .ContentMenuHeaderIcon{
        display: none;
    }
}
.WrapperFooter {
    width: 100%;
    background-color: #fff;
    padding: 2rem 2.5rem 1rem 2.5rem;
}

.WrapperFooterCot {
    width: 100%;
    background-color: #fff;
    padding: 2rem 2.5rem 1rem 2.5rem;
}

.TopFooter {
    width: 100%;
    display: block;
    flex-wrap: wrap;
}

.ContainerFooter1 {
    width: 30%;
    text-align: left;
    margin-bottom: 1rem;
}

.ContainerFooter2 {
    width: 20%;
    text-align: left;
    margin-bottom: 1rem;
}

.ContainerFooter3 {
    width: 50%;
    margin-bottom: 1rem;
}

.BottomFooter {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
}

.TextFooter1 {
    font-size: 16px;
    width: 100%;
    text-align: center;
    padding: 1px 0;
}

.TextFooter2 {
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    padding: 7px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.TextFooter3 {
    padding-left: 7px;
    padding-bottom: 10px;
}


.TitleFooter {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    text-align: left;
    padding: 10px 0;
}

.LogoImgFooter {
    width: 230px;
    margin: auto 0;
    text-align: left;
    padding-bottom: 8px;
}

.IconContactFo {
    color: #fff;
    display: flex;
    align-items: center;
    background-color: #8bc540;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
    margin-right: 0.5rem;
    cursor: pointer;
}

.ContainerRedesF {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width:900px) {
    .TopFooter {
        display: flex;
    }

    .ContainerFooter1 {
        margin-bottom: 0;
    }

    .ContainerFooter2 {
        margin-bottom: 0;
    }

    .ContainerFooter3 {
        margin-bottom: 0;
    }
}

@media(max-width: 700px){
    .WrapperFooterCot{
        margin-top: 115vh;
    }
}
.MainWrapper{
    position: relative;
    width: 100vw;
}

.SliderMain{
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    overflow: hidden;
}
.MainContainer{
    min-width: 100%;
    height:800px;
    position: relative;
}

.BannerMain{
    width: 100%;
    height: 800px;
    object-fit: cover;
    position: absolute;
    z-index: 5;
}

.InfoMain{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
    background-color: rgba(0, 0, 0, 0.3);
}
.ContainerArrowM{
    position: absolute;
    z-index: 7;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 2rem;
}
.SliderArrowMB{
    cursor: pointer;
    color:  #8bc540;
    border: none;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    width: 2rem;
    height: 2rem;
    border-radius: 30px;
    margin: auto 0;
}
.TextMain{
    width: 90%;
    text-align: center; 
}

.TitleMain{
    color: white;
    font-size: 38px;
    font-weight: 700;
    padding: 2rem 1.5rem;
}
.ButtonMain{
    background-color: #8bc540 ;
    color: #fff ;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    margin: 2rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    text-align:center;
    cursor: pointer;
    text-decoration: none;
}
#CertiArrowLeftMain{
    left: 0;
}
#CertiArrowRightMain{
    right: 0;
}
.WrapperSomos{
    background-color: #e6e7ee;
    padding: 3rem 2.5em;
}
.TitleSomos{
    font-weight: 700;
    font-size: 28px;
    opacity: 0.8;
    padding-bottom: 1rem;
    margin: 0;
}
.DescriptionSomos{
    width:100%;
    font-weight: 400;
    font-size: 19px;
    line-height:1.5em;
    margin: 0;
}

@media (min-width:900px) {
    .WrapperSomos{
        padding: 3rem 7em;
    }

}


.Wrapper1 {
    background-color: #fff; 
}

.Wrapper2 {
    margin: 0;
    display:flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
}

.Title {
    font-weight: 700;
    font-size: 30px;
    opacity: 0.8;
    padding: 3.5rem 0;
    text-align: center;
    margin: 0;
}

.Container {
    display:flex;
    flex-wrap: wrap;
    width: 100%; 
}


.Option-normal{
    background-color: #ffff;
    text-decoration: none;
    width: 100%;
    height: 25% !important;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8px;
    border-right: 1px solid #dbdbdb;
    border-bottom:1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
    padding: 1rem 4rem;
} 

.Option-normal:hover{
    background-color:  #f3f1f1;
    cursor: pointer;
}

.Option-selected {
    background-color:#8bc540;
    text-decoration: none;
    width: 100%;
    height: 25% !important;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8px;
    border-right: none;
    border-left: 1px solid #dbdbdb;
    color:  #ffff ;
    padding: 1rem 4rem;
}

.Description {
    padding: 1rem 2rem;
    width: 85%;
    margin: auto;
}

.Destitle {
    font-weight: 700;
    font-size: 20px;
}

.DesParagraph{
    font-weight: 500;
    font-size: 16px;
    margin: 1.5rem 0;
}

.Wrapper3{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Image {
    width: 35%;
    display: none;
}

@media (min-width:1000px) {
    .Wrapper2{
        margin: 0 7rem;
    }
}
@media (min-width:900px) {
    .Container {
        width: 25%; 
    }
    .Option-normal{
        padding: 0;
        background-color: #ffff;
        text-decoration: none;
        width: 100%;
        height: 25% !important;
        font-weight: 700;
        font-size: 15px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 8px;
        border-right: 1px solid #dbdbdb;
        border-bottom: none;
        border-top: none;
    } 
    .Option-selected {
        padding: 0;
        background-color: #ffff;
        font-weight: 700;
        font-size: 15px;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
        color:  #8bc540;
    }
    .Wrapper2{
        margin: 0 2rem;
    }
    .Description {
        margin: 0;
        width: 75%;
    }
    .Image {
        display: block;
    }
}


.WrapperGara{
    color:  #fff;
    background-color:  #8bc540;
    width:100%;
} 

.ContainerGara1{
    width:100%;
    padding: 1.5rem 2rem;
    flex-wrap: wrap;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ContainerGara2{
    width:100%;
    text-align: center;
}
    
.ImageGara{

    width: 120px;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
}
.TextGara1{
    font-weight: 700;
    font-size: 32px;
    padding: 2rem;
    padding-left: 0;
    text-align: center;
}

.TextGara2{
    font-weight: 500;
    font-size: 24px;
    padding-bottom: 4rem;
    width:85%;
    margin: auto;
}


@media (min-width:900px) {
    .TextGara1{
        padding-left: 3rem;
    }
    .ContainerGara1{
        display: flex;
    }
    .TextGara2{
        font-weight: 500;
        font-size: 24px;
        padding-bottom: 4rem;
        width:100%;
        text-align:center;
    }
}


.WrapperProyectos{
    width:100%;
    background-color: #fff; 
} 
  
.TitleProyectos{
    font-weight: 700;
    font-size: 30px;
    opacity: 0.8;
    padding: 3.5rem 0;
    text-align: center;
}
    
.ContainerProyectos{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}
    
.ImageBlockPro{
    width: 50%;
    height: 330px;
    position: relative;
}

.TextContainerPro{
    width:100%;
    height:100%;
    position: absolute;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap:wrap;
    cursor: pointer;    
}
   
.TextContainerPro:hover{
    background-color: rgba(0,0,0,.7);
}
.TextContainerPro:hover div{
    display: block;;
}

.TextTitlePro{
    font-weight: 700;
    font-size: 22px;
    color: #fff;
    padding-bottom: 0.5rem;
    text-align:center;
    width:100%;
    display: none;
}
    
.TextDescPro{
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    width:100%;
    text-align:center;
    display: none;
}

.ImagePro{
    width: 100%;
    height: 330px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
}



.ImageBlock{
    width: 100%;
    height: 330px;
    position: relative;
    cursor: pointer;
}

.ImagePro{
    width: 100%;
    height: 330px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
}

.TextContainer{
    width:100%;
    height:100%;
    position: absolute;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap:wrap;
    border: none;
    background-color: rgba(0,0,0,.7);
}

.TextContainer:hover{
    background-color: rgba(0,0,0,.7);
}
.TextContainer:hover p{
    display: block;
}
    
.TextTitle{
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    color: #fff;
    padding-bottom: 0.5rem;
    text-align:center;
    width:100%;
    display: block;
}

.TextDesc{
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    width:100%;
    text-align:center;
    display: block;
}

.DisplayImagesFalse{
    display: none;
    background-color:rgba(38,38,38,.86);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 40;
}

.DisplayImagesTrue{
    background-color:rgba(38,38,38,.86);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 40;
    height: 100%;
}

.DIContainerclose{
    text-align: right;
    margin: 2rem;
    cursor: pointer;
}

.DIClose{
    color: #fff;
    font-size: 30px;
    font-weight: 100;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.DIArrow{
    width: 5%;
    color: #fff;
    font-size: 25px;
    font-weight: 100;
   
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.DIContainerImage{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.DICimage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    text-align: center;
}
.DIimage{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.DIText{
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}

@media (min-width:900px) {
    .DIimage{
        height: 540px;
    } 
}

@media (min-width:500px) {
    .DIimage{
        height: 450px;
    } 
}

@media (min-width:900px) {

    .ImageBlock{
        width: 50%;
    }
    .TextTitle{
        display: none;
    }
    .TextDesc{
        display: none;
    }
    .TextContainer{
        background-color: transparent;
    }
}
.WrapperBFinan{
    color:  #fff;
    background-color:  #8bc540;
    width:100%;
}

.ContainerBFinan1{
    width:100%;
    padding: 2.5rem 2rem;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}
    

.ContainerBFinan2{
    width:100%;
}
    
.TextBFinan1{
    width:100%;
    font-weight: 700;
    font-size: 28px;
    padding-left: 3rem;
    text-align:center;
}
    
.ButtonBFinan{
    background-color: #fff;
    color: #8bc540;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    margin: 2rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 400;
    font-size: 20px;
    text-align:center;
    cursor: pointer;
    text-decoration: none;
}

.ContainerParteDe{
    width: 100%;
    padding: 4rem 0;
    text-align:center;
    background-color: #ffff;
}

.BannerParteDe{
    width: 70%;
    margin: 3rem 0;
}

.TextParteDe{
    font-weight: 700;
    font-size: 30px;
    opacity: .8;
}

@media (min-width:900px) {
    .BannerParteDe{
        width: 500px;
    }
}

.ContainerDistri{
    width: 100%;
    padding: 4rem 1rem;
    text-align:center;
    background-color: #e6e7ee;
}

.BannerDistri{
    width: 40%;
}

.TextDistri{
    font-weight: 700;
    font-size: 30px;
    opacity: .8;
}

.ImgContainerDistri{
    width:80%;
    text-align:center;
    justify-content: center;
    display:flex;
    flex-wrap: wrap;
    margin: auto;
}

@media (min-width:900px) {
    .BannerDistri{
        width: 30%;
    }    
    .ContainerDistri{
        padding: 4rem;
    }
}

.WrapperS1{
    background-color: #fff; 
}

.WrapperS2 {
    margin: 0;
    display:flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
}

.TitleS{
    font-weight: 700;
    font-size: 30px;
    opacity: 0.8;
    padding: 3.5rem 0;
    text-align: center;
    margin: 0;
}

.ContainerS{
    display:flex;
    flex-wrap: wrap;
    width: 100%; 
    height: -webkit-fit-content; 
    height: -moz-fit-content; 
    height: fit-content;
    
}


.OptionS-normal{
    background-color: #ffff;
    text-decoration: none;
    width: 100%;
    height: 25% !important;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8px;
    border-right: 1px solid #dbdbdb;
    border-bottom:1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
    padding: 1rem 4rem;
} 

.OptionS-normal:hover{
    background-color:  #f3f1f1;
}

.OptionS-selected {
    background-color:#8bc540;
    text-decoration: none;
    width: 100%;
    height: 25% !important;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8px;
    border-right: none;
    border-left: 1px solid #dbdbdb;
    color:  #ffff ;
    padding: 1rem 4rem;
}

.DescriptionS{
    padding: 1rem 2rem;
    width: 85%;
    margin: auto;
}

.DestitleS{
    font-weight: 700;
    font-size: 20px;
}

.DesParagraphS{
    font-weight: 500;
    font-size: 16px;
    margin: 1.5rem 0;
}

.WrapperS3{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ImageS{
    width: 35%;
    display: none;
}

@media (min-width:1000px) {
    .WrapperS2{
        margin: 0 7rem;
    }
}
@media (min-width:900px) {
    .ContainerS{
        width: 25%; 
    }
    .OptionS-normal{
        background-color: #ffff;
        text-decoration: none;
        width: 100%;
        height: 25% !important;
        font-weight: 700;
        font-size: 15px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 8px;
        border-right: 1px solid #dbdbdb;
        border-bottom: none;
        border-top: none;
    } 
    .OptionS-selected {
        padding: 0;
        background-color: #ffff;
        font-weight: 700;
        font-size: 15px;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
        color:  #8bc540;
    }
    .WrapperS2{
        margin: 0 2rem;
    }
    .DescriptionS{
        margin: 0;
        width: 75%;
    }
    .ImageS{
        display: block;
    }
    .ContainerS{
        height: auto;
        align-content: center;
    }
}

.WrapperBenifi{
    width: 100%;
    background-color: #8bc540;
    display: flex;
    flex-wrap: wrap;
    padding: 4rem;
}

.ContainerBenifi{
    text-align: center;
    width: 100%;
    padding: 1rem 0;
}

.ContainerIcon{
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 1rem;
    width: 80px;
    padding: 1rem;
}

.Icon{
    width:100%;
}

.Message{
    width: 100%;
    text-align: center;
}

.TextBig{
    font-weight: 700;
    font-size: 32px;
    color: #fff;
    margin: 0;
    margin-bottom: 0.3rem;
}

.TextSmall{
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    margin: 0;
}

@media (min-width:900px) {
    .ContainerBenifi{
        width: 25%;
        padding: 4rem 0;
    } 

    .WrapperBenifi{
        padding: 0;
    }
}

.WrapperCerti{
    width: 100%;
    padding: 1rem 1rem;
    text-align:center;
    background-color: #e6e7ee;
}

.TitleInfoCerti{
    text-align: center;
    width: 100%;
    padding: 2.5rem 0;
}
.TitleCerti1{
    color: #8bc540;
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 0.5rem;
    margin: 0;
}
.TitleCerti2{
    font-size: 30px;
    font-weight: 700;
    margin: 0;
}
.WrapperSlider{
    width: 70%;
    margin: 2rem auto; 
    height: 200px;
    position: relative;
    overflow: hidden;
    z-index:1
}
.WrapperSliderImgs{
    min-width: 100%;
    display: flex;
    flex-wrap:nowrap;
    position: absolute;
    z-index: 5;
}
.SliderImg{
    max-height: 200px;
    padding: 0 4rem;
    transition: .3s ease all;
}
.ContainerArrowC{
    position: absolute;
    z-index: 7;
    height: 100%;
    display: flex;
    align-items: center;
}
.SliderArrow{
    color:  #8bc540;
    border: none;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    width: 2rem;
    height: 2rem;
    border-radius: 30px;
    margin: auto 0;

    
}
#CertiArrowLeft{
    left: 0;
}
#CertiArrowRight{
    right: 0;
}

@media (min-width:900px) {
    .SliderImg{
        padding: 0 5rem;
    }

}

@media (min-width:600px) {
    .SliderImg{
        padding: 0 4rem;
    }
    .WrapperSlider{
        width: 80%;
    }
}
.ContainerEmpresa1{
    width: 100%;
    padding-left: 0.5rem;
    background-color: #e6e7ee;
    padding-bottom: 3rem;
}

.ContainerEmpresa2{
    width: 100%;
    background-color: #e6e7ee;
}

.OptionEmpresa1{
    width: 100%;
    padding: 0 2em;
    padding-bottom: 1rem;
}

.OptionEmpresa2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ButtonEmpresaNormal{
    cursor: pointer;
    width: 100%;
    background-color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1.3rem 3rem;
    border: 0.3px solid #dadada;
    border-radius: 5%;
    margin: 0.5rem 0;
}

.ButtonEmpresaNormal:hover{
    border: 0.7px solid #bdbdbd;
}

.ButtonEmpresaSelected{
    width: 100%;
    color: #fff;
    background-color: #8bc540;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 3rem;
    border: 0.3px solid #dadada;
    border-radius: 5%;
    margin: 0.5rem 0;
}

.OptionDescription{
    width: 100%;
    background-color: #fff;
    padding: 0.5rem;
    margin-top: 1rem;
    font-size: 18px;
}
.TextDescription{
    margin: 1.5rem 2rem;
}

@media (min-width:1000px) {
    .ContainerEmpresa2{
        width: 65%;
    }
    .OptionEmpresa1{
        width: 65%;
        padding: 0 7em;
    }
    .ButtonEmpresaNormal{
        width: 30%;
        margin: 0;
        font-size: 15px;
    }
    .ButtonEmpresaSelected{
        width: 30%;
        margin: 0;
        font-size: 15px;
    }
    .OptionDescription{
        font-size: 18px;
        height: 15vh;
    }
}
.WrapperBPerfil{
    width: 100%;
    height: 400px;
    position: relative;
    background-color: red;
    overflow: hidden;
}
.ImgBPerfil{
    position: absolute;
    z-index: 5;
    max-height:100%;
    min-width:100%;
}
.GapBPerfil{
    min-height: 5rem;
}
.InfoBPerfil{
    position: absolute;
    min-height:100%;
    width: 100%;
    z-index: 6;
    background-color: #55881ed2;
}
.ContiBPerfil{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    position: relative;
    padding-left: 1rem;
}

.TitleBPerfil{
    width: 30%;
    max-height: 2rem;
    font-size: 0.9rem;
    font-weight: 300;
    color: #ffff;
    text-align: right;
    margin: auto;
    padding-left: 2rem;
}
.TextBPerfil{
    width: 70%;
    max-height: 2rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: #ffff;
    text-align: left;
    margin: 0.6rem auto;
    padding-left: 1rem;
}
.ContibuttonBPerfil{
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: 3rem auto;
    
}
.Button1BPerfil{
    color: #262626;
    display: block;
    background-color: #ffff;
    border: none;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0.3rem 2rem;
    cursor: pointer;
    margin: 1rem auto;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.Button1BPerfil:hover{
    background-color: #8FC456;
    color: #ffff;
    
}
.Button2BPerfil{
    display: block;
    color: #262626;
    background-color: #ffff;
    border: none;
    font-size: 0.6rem;
    font-weight: 500;
    padding: 0.3rem 2rem;
    cursor: pointer;
    margin: 1rem auto;
    transition: 0.3s;
    text-decoration: none;
    text-align: center;
    border-radius: 15px;
}
.Button2BPerfil:hover{
    background-color: #8FC456;
    color: #ffff;
}

@media (min-width:900px) {
    .WrapperBPerfil{
        width: 35%;
        height: 700px;
    }
}
.WrapperCambioContra{
    background-color:rgba(38,38,38,.86);
    position: fixed;
    z-index: 40;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
}
.FormCambioContra{
    width: 95%;
    background-color: #ffff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    text-align: center;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column  ;
    align-items: center;
}
.InputCambioC{
    width: 80%;
    height: 2.3rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
    margin-bottom: 1em;
}

.InputCambioC::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputCambioC:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputCambioC::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.LabelCambioC{
    font-size: 1rem;
    font-weight: 700;
    color: #8FC456;
    display: block;
    width: 80%;
    text-align: left;
    margin: auto;
    padding-bottom: 0.3rem;
}
.ButtonCambioC1{
    width: 60%;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 0.4rem;
    color: #ffff;
    background-color: #8FC456;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem 0;
    cursor: pointer;
    text-align: center;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonCambioC1:hover{
    background-color: #8FC456;
}
.ButtonCambioC2{
    width: 45%;
    margin: auto;
    margin-bottom: 1rem;
    color: #c2c2c5;
    border: none;
    background-color: #ffff;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 0;
    cursor: pointer;
    text-align: center;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonCambioC2:hover{
    color: #8FC456;
}

.contentModal{
    border-radius: 1rem;
    width: 80vw;
}

.inputDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 7em;
}

.alertChangePassword{
    color: red;
    font-size: 8pt;
    font-weight: bold;
    text-decoration:underline;
    margin-bottom: 1rem;
}

@media(min-width: 1100px){
    .contentModal{
        border-radius: 1rem;
        width: 35vw;
    }
}
.WrapperDashA{
    width: 100%;    
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content:flex-start;
}
p {
    margin: 0px;
}
.ConteDACitas{
    max-width: 600px;
    background-color: #ffff;
    box-shadow: 8px 6px 20px -2px rgba(0,0,0,0.1);
    border: 1px solid #ebebeb8e;
    border-radius: 5%;
    padding: 1rem 1.5rem;
    margin: 1.5rem;
}
.DACitasRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-content: center;
    padding: 0.8rem 0rem ;
}
.DACitasTitle{
    font-size: 1.2rem;
    font-weight: 600;
    color: #262626;
    text-align: left;
    border-bottom: 3px solid #8FC456;
}
.DACitasNum{
    font-size: 1.3rem;
    font-weight: 700;
    text-align: left;
    padding-left: 1rem;
    color: #8FC456;
}

.DACita{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
    height: 70px;
    border-bottom: 1px solid #f1f1f1;
}

.DACita:hover{
    background-color: #a1a1a131;
}
.DACitaImgUser{
    width: 15%;
    max-width: 55px;
    margin: auto 0;
}
.DACitaInfoB{
    width: 65%;
    padding-left:0.6rem;
    padding-right:0.6rem;
    margin: auto;
}
.DACitaTitle{
    font-size: 0.8rem;
    font-weight: 600;
    text-align: left;
}
.DACitaText1{
    color: #a1a1a1;
    font-size: 0.7rem;
    font-weight: 400;
    text-align: left;
}

.DACitaText2{
    color: #181717;
    font-size: 0.7rem;
    font-weight: 400;
    text-align: left;
}

.DACitaDate{
    width: 20%;
    margin: auto;
}
.DACitaText2{
    font-size: 0.7rem;
    font-weight: 600;
    text-align: left;
    /* margin: auto; */
}

.DACitasPages{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DACitasPageSelect{
    font-size: 0.8rem;
    font-weight: 700;
    color: #8FC456;
    padding: 0 0.1rem;
    margin: auto 0.2rem;
    border-bottom: 2px solid #8FC456;;
}

.DACitasPage{
    font-size: 0.8rem;
    font-weight: 700;
    color: #8fc45694;
    padding: 0 0.1rem;
    margin: auto 0.2rem;
}

@media (min-width:600px) {
    .ConteDACitas{
        width: 90%;
        margin: 0px auto auto;
        /* max-width: 65; */
    }
}
@media (min-width:900px) {
    .WrapperDashA{
        width: 65%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content:flex-start;
        padding-top: 0.5rem;
        max-height: 700px;
    }
    .ConteDAEstadi{
        width: 80%;
    }
    .ConteDACitas{
        width: 90%;
        max-height: 650vh;
        /* overflow-y: scroll; */
    }
    .DACitasConte{
        width: 100%;
        max-height: 600px;
        overflow-y: auto;
    }
}


.WrapperFormCotiza1{
    background-color: #e6e7ee;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    padding: 5rem 0;
}
.WrapperFormCotiza2{
    background-color: #fff;
    width: 85%; 
    margin: auto;
    box-shadow: 6px 9px 26px -8px hsl(0deg 0% 51% / 40%);
    display: flex;
    flex-wrap: wrap; 
}
.ContactFC{
    background-color: #8bc540;
    width: 100%;
    text-align: left;
    padding: 1rem 3rem;
}
.TitleContactFC{
    font-size: 25px;
    font-weight: 400;
    color: #fff;
}
.TextContactFC{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin: 2rem 0;
}
.IconContactFC{
    color: #fff;
    display: flex;
    align-items: center;
    background-color: #a3d167;
    justify-content: center;
    min-width: 3rem;
    height: 3rem;
    border-radius: 30px;
}
.Text1ContactFC{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    padding-left: 1rem;
}
.Text2ContactFC{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: inline-block;
    padding-left: 0.5rem;
}
.FormularioFC{
    width: 100%;
    padding: 0 5rem;
}
.FormTitleFC{
    width: 100%;
    text-align: left;
    font-size: 24px;
    font-weight: 400;
    padding: 1rem 0;
}
.FormFC{
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
}
.InputFC1{
    height: 52px;
    box-shadow: inset 0 1px 2px rgb(38 38 38 / 10%);
    border: 1px solid transparent;
    max-width: 100%;
    width: 100%;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    padding-left: 0.5rem;
    margin: 1rem 0;
    display: block;
    font-size: 0.9rem;
}

.InputFC2{
    height: 52px;
    box-shadow: inset 0 1px 2px rgb(38 38 38 / 10%);
    border: 1px solid transparent;
    max-width: 100%;
    width: 100%;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    padding-left: 0.5rem;
    margin: 1rem 0;
    font-size:  0.9rem;
}

.ButtonFC{
    width: 100%;
    background-color: #8bc540;
    border-color: transparent;
    box-shadow: none;
    opacity: .5;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    margin: 2rem 0;
}
.ButtonFCAct{
    width: 100%;
    background-color: #8bc540;
    border-color: transparent;
    box-shadow: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    margin: 2rem 0;
    cursor: pointer;
}
#comment{
    height: 152px;
}

@media (min-width:1000px) {
    .FormularioFC{
        width: 60%;
    }
    .ContactFC{
        width: 40%;
    }
    .InputFC2{
        width: 45%;
    }
}

.WrapperSucursal{
    width: 100%;
    background-color: #fff;
    padding: 3rem 2rem ;
}
.SucursalTitle{
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 2rem;
}
.Text2ContactSC{
    text-align: left;
    font-size: 20px;
    padding-left: 1rem;
    margin: 0;
}
.Text1ContactSC{
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    padding-left: 1rem;
    margin: 0.2rem 0;
}
.ContainerSc{
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 1rem 0;
}
.SucursalContact{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.IconContactSC{
    color: #fff;
    display: flex;
    align-items: center;
    background-color: #8bc540;
    justify-content: center;
    min-width: 3rem;
    height: 3rem;
    border-radius: 30px;
}
.SucursalMap{
    background-color: #8bc540;
    width: 100%;
    min-height: 400px;
    margin: 2rem 0;
}

@media (min-width:900px) {
    .WrapperSucursal{
        padding: 3rem 8rem ;
    }
}
.WrapperBRegistro{
    width: 100%;
    height: 400px;
    position: relative;
    background-color: red;
    overflow: hidden;
}
.ImgBRegistro{
    position: absolute;
    z-index: 5;
    max-height:100%;
    min-width:100%;
}
.InfoBRegistro{
    position: absolute;
    min-height:100%;
    width: 100%;
    z-index: 6;
    background-color: #55881ed2;
    /* background-color: #697a57d2; */
    text-align: center;
}
.TitleBRegistro{
    width: 80%;
    font-size: 2.5rem;
    font-weight: 700;
    color: #ffff;
    margin: 5rem auto 3rem ;
}

.TextBRegistro{
    width: 60%;
    font-size: 1.3rem;
    font-weight: 500;
    color: #ffff;
    margin: auto;
    text-align: center;
}

@media (min-width:900px) {
    .WrapperBRegistro{
        width: 35%;
        height: 800px;
    }
    .TitleBRegistro{
        width: 100%;
    }
    .TextBRegistro{
        text-align: left;
    }
    
    
}
.WrapperFormLogin{
    width: 100%;
    text-align: center;
    background-color: #ffff;
}

.TitleFLogin{
    color: #262626;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #8FC456;
    padding: 0 3rem;
    margin: 3rem auto;
    font-size: 30px;
    font-weight: 600;
}
.ContainerFLogin{
    width: 65%;
    margin: auto;
}
.LabelFLogin{
    width: 60%;
    padding-right: 1rem;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    display: block;
    margin-bottom: 0.5rem;
}
.SubLabelFLogin{
    padding-left: 0.5rem;
}
.InputFLogin{
    width: 100%;
    height: 2.5rem;
    margin: auto;
    margin-bottom: 1rem;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
    margin-bottom: 2rem;
}

.InputFLogin::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFLogin:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFLogin::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.FormLogin{
    text-align: center;
}
.ButtonFLogin{
    margin: 3rem auto;
    color: #ffff;
    background-color: #8FC456;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonFLogin:hover{
    background-color: #8FC456;
}

.InputFLoginPass{
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
    margin-bottom: 2rem;
}

.InputFLoginPass::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFLoginPass:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFLoginPass::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.ContePassword{
    position: relative;
    width: 100%;
    height: 2.5rem;
    margin: auto;
}
.ContePassword button{ 
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
}
.ContePassword i{ 
    font-size: 0.8rem;
    color: #9C9C9C;
    padding-right: 0.5rem;
    padding-top: 0.9rem;
    cursor: pointer;
}
.ContePassword button::-webkit-input-placeholder i{ 
    color: #535353;
}
.ContePassword button:-ms-input-placeholder i{ 
    color: #535353;
}
.ContePassword button::placeholder i{ 
    color: #535353;
}
@media (min-width:900px) {
    .WrapperFormLogin{
        width: 65%;
    }
    .ContePassword{
        width: 60%;
    }
    .InputFLogin{
        width: 60%;
    }
    .LabelFLogin{
        margin: auto;
    }
}
.WrapperInfoFinanza{
    background-color: #e6e7ee;
    width: 100%;
    padding: 0 0.8rem;
}
.TitleInfoFinanza{
    text-align: center;
    width: 100%;
    padding: 4rem 0;
}
.TitleIF1{
    color: #8bc540;
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 0.5rem;
    margin: 0;
}
.TitleIF2{
    font-size: 30px;
    font-weight: 700;
    margin: 0;
}

.WrapperBlocksIF{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.BlockImgIF{
    background-color: #8bc540;
    min-height: 250px;
    width: 50%;
    margin: 1.2rem;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
}
#BlockImgIF1{
    background-image: url(/static/media/funding-1.1fcda535.jpg);
}
#BlockImgIF2{
    background-image: url(/static/media/funding-2.4d75e9ab.jpg);
}
#BlockImgIF3{
    background-image: url(/static/media/funding-3.b7565235.jpeg);
}
.BlockImgFunding{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.ImageIF{
    width: 30%;
    margin: 0 0.5rem ;
}
.BlockImgIF:hover{
    box-shadow: 6px 9px 26px -8px hsl(0deg 0% 51% / 40%);
}

@media (min-width:1000px) {
    .BlockImgIF{
        display: block;
    }
    .ImageIF{
        width: 18%;
        margin: 0;
    }
    .BlockImgFunding{
        justify-content: space-between;
    }
}

@media (min-width:900px) {
    .WrapperInfoFinanza{
        padding: 0 5rem;
    }
    
}


.BlockTextIF{
    background-color: #fff;
    padding: 2rem 2rem;
    text-align: left;
    width: 100%;
    margin: 0.5rem;
}
.BlockTtiletIF{
    font-size: 20px;
    font-weight: 700;
    margin: 0.2rem 0;
}
.BlockText1IF{
    font-size: 15px;
    font-weight: 400;
    margin: 2rem 0;
}
.BlockText2IF{
    font-size: 12px;
    font-weight: 400;
    margin: 2rem 0;
}
.BlockTextIF:hover{
    box-shadow: 6px 9px 26px -8px hsl(0deg 0% 51% / 75%);
}
@media (min-width:1000px) {
    .BlockTextIF{
        width: 40%;  
        margin: 1.2rem;
    }
}


.BlockTextIF2{
    background-color: #fff;
    padding: 2rem 2rem;
    text-align: left;
    width: 100%;
    margin: 0.5rem;
    min-height: 200px;
}
.BlockTtiletIF{
    font-size: 20px;
    font-weight: 700;
    margin: 0.2rem 0;
}
.BlockText1IF{
    font-size: 15px;
    font-weight: 400;
    margin: 2rem 0;
}
.BlockText2IF{
    font-size: 12px;
    font-weight: 400;
    margin: 2rem 0;
}
.BlockTextIF2:hover{
    box-shadow: 6px 9px 26px -8px hsl(0deg 0% 51% / 75%);
}

@media (min-width:1000px) {
    .BlockTextIF2{
        width: 30%;  
        margin: 1.2rem;
        min-height: 250px;
    }
}


.WrapperBCoti{
    width: 100%;
    height: 450px;
    position: relative;
    background-color: #4b763791;
    overflow: hidden;
}
.ImgBCoti{
    position: absolute;
    z-index: 5;
    max-height:100%;
    min-width:100%;
}
.InfoBCoti{
    position: absolute;
    min-height:100%;
    width: 100%;
    z-index: 6;
    background-color: #4b763791;
    text-align: center;
}
.TitleBCoti{
    width: 80%;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 5rem auto 3rem ;
}

.TextBCoti1{
    width: 60%;
    font-size: 1.3rem;
    font-weight: 500;
    color: #ffff;
    margin: auto;
    text-align: center;
}
.ContactoBCoti{
    margin-top: 15rem;
}
.TextBCoti2{
    font-size: 0.9rem;
    font-weight: 400;
    color: #ffff;
    margin: auto;
    margin-bottom: 0.2rem;
    text-align: left;
    padding-left: 1.5rem;
}
.TextBCoti3{
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffff;
    margin: auto;
    margin-bottom: 0.5rem;
    text-align: left;
    padding-left: 1.5rem;
}
.TextBCoti3 i{
    font-size: 1.8rem;
    font-weight: 500;
    color: #ffff;
    margin: 1rem auto;
    text-align: left;
    padding-left: 1.5rem;
    padding-right: 1rem;
}
/*Colors*/
.ColorGreen1{   background-color: rgb(75, 118, 55, 0.85) }
.ColorGreen2{   background-color: #88C540 }
.ColorWhite{    background-color: white;}
/*Text Color*/
.TextColorYellow{ color:#FFC30E }
.TextColorWhite{ color: white }

/*Positions*/
.PositionRelative{  position: relative }
.PositionFixed{     position: fixed    }
@media (min-width:900px) {
    .WrapperBCoti{
        width: 30%;
        height: 800px;
    }
    .TitleBCoti{
        width: 100%;
    }
    .TextBCoti1{
        text-align: left;
    }
    
    
}
.WrapperFormsCotiza{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}
.FormsCotiza{
    width: 65%;
}
.FCotizaTitle{
    color: #295B85;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    padding-right: 4rem;
    padding-bottom: 0.7rem;
    margin: 4rem;
    margin-bottom: 2.5rem;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.TimeLineCotizador{
    width: 35%;
    min-height: 100%;
    margin-top: 3px;
    background-color: rgb(221, 221, 221);
}
.FCotiLineTitle{
    color: #295B85;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    margin: 3.6rem 4rem 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0.5rem;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.WrapperTimeline{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}
.ContainerTimeline{
    text-align: left;
    width: 100%;
    margin: auto;
    padding-right: 2rem;
}
.TitleTL{
    position: absolute;
    z-index: 5;
    left: 2rem;
    font-size: 0.8rem;
    width: -webkit-max-content;
    width: max-content;
    font-weight: 400;
}
.TitleTLSelect{
    position: absolute;
    z-index: 5;
    left: 2rem;
    font-size: 0.9rem;
    width: -webkit-max-content;
    width: max-content;
    font-weight: 600;
    
}
.PointTLFalse{
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 5rem;
    background-color: #d4d3d3;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LineTLFalse{
    width: 0.2rem;
    height: 3.5rem;
    background-color: #d4d3d3;
    margin: auto;
}
.PointTLTrue{
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 6rem;
    background-color: #22b601;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-timing-function: ease-in;
    transition: 0.8s;
}
.PointTLFalse i{
    padding-top: 0.1rem;
    font-size: 0.9rem;
    color: #ffff;
    margin: auto;
    display: none;
    transition-timing-function: ease-in;
    transition: 0.8s;
}
.PointTLTrue i{
    padding-top: 0.1rem;
    font-size: 0.9rem;
    color: #ffff;
    margin: auto;
    transition-timing-function: ease-in;
    transition: 0.8s;
}
.LineTLTrue{
    width: 0.2rem;
    height: 3.5rem;
    background-color: #22b601;
    margin: auto;
}

@media(max-width: 900px){
    .WrapperFormsCotiza{
        width: 100%;
        height: 120vh;
    }
}

@media(max-width: 700px){
    .WrapperFormsCotiza{
        display: block;
    }
    .FormsCotiza{
        width: 100%;
        margin-top: -2rem;
    }
    .TimeLineCotizador{
        width: 100%;
        height: -webkit-max-content;
        height: max-content;
        position: absolute;
        margin-top: 10rem;
    }
}
.WrapperFormPreRe{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    /* background-color: blue; */
}

.LabelFCoti2{
    width: 35%;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}

.SubtitleFCoti{
    width: 100%;
    padding-left: 1rem;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 2rem auto 1rem;
    color: #1d5481;
}
.InputFCoti{    
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
    
}
.InputFCoti select{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
    position: absolute;
    z-index: 2;
}  

.InputFCoti #no-select{
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCoti:after{
    position: absolute;
    z-index: 3;
    right: 0;
    padding-right: 1rem;
    padding-bottom: 0.4rem;
    font-family: FontAwesome;
    font-weight: 400;
    content: '\f0dd';
    color: #1d5481 ;
    font-size: 1.5rem;
    margin: auto;
    min-height: 100%;
    min-height:-webkit-max-content;
    min-height:max-content;
}

.LabelFCoti2{
    width: 35%;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}

.ButtonFCotiza{
    margin: 1rem auto;
    color: #ffff;
    background-color: #4b7637;
    border: none;
    border-radius: 20rem;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    transition: 0.3s;
}

.ButtonFCotiza:hover{
    background-color: #6ca031;
}

.BlockRFPreRe{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.RadioFCoti{
    -webkit-appearance: none;
    appearance: none;
}
.RadioFCoti#Casa::before{
    content: "\f015";
    font-family: FontAwesome;
    font-size: 1.2rem;
    padding-right: 0.5rem;
    color: #b4b4b4;
}
.RadioFCoti#Casa:checked::before{
    color: #1d5481;
    transition: 0.4s;
}
.RadioFCoti#Casa:checked ~ .LabelRFPreRe{
    color: #1d5481;
    transition: 0.4s;
    font-weight: 500;
}
.RadioFCoti#Negocio::before{
    content: "\f1ad";
    font-family: FontAwesome;
    font-size: 1.1rem;
    padding-right: 0.5rem;
    color: #b4b4b4;
}

.RadioFCoti#Negocio:checked::before{
    color: #1d5481;
    transition: 0.4s;
}
.RadioFCoti#Negocio:checked ~ .LabelRFPreRe{
    color: #1d5481;
    transition: 0.4s;
    font-weight: 500;
}
.LabelRFPreRe{
    width: 50%;
    color: #8f8f8f;
    font-size: 16px;
    font-weight: 500;
}

.LabelAlert{
    color: red;
    width: 70%;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 900;
    margin: auto;
    text-align: center;
}

@media(max-width: 700px){
    .ButtonFCotiza{
        height: 3rem;
        text-transform: uppercase;
    }
}

@media(min-width: 700px){
    .ButtonFCotiza{
        margin-top: 3rem;
    }
}
.WrapperFormEnergia{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.LabelFCoti3{
    width: 60%;
    padding-left: 1rem;
    font-size: 16px;
    font-weight: 500;
    margin: 1.5rem auto;
    display: flex;
    justify-content: left;
}

.BlockRFEnergia{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.InputFCotiNum1{
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
}
.InputFCotiNum1 input{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
}
.InputFCotiNum1 input::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1 input:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1 input::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1:after{
    position: absolute;
    z-index: 3;
    right: 0;
    content: 'KW';
    color: #9C9C9C;
    font-size: 0.8rem;
    font-weight: 500;
    padding-right: 1rem;
}

.InputFCotiNum1 input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNum1 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.InputFCotiNum2{
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
}
.InputFCotiNum2 input{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 2rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
}
.InputFCotiNum2 input::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2 input:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2 input::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2:after{
    position: absolute;
    z-index: 3;
    left: 0;
    content: '$';
    color: #9C9C9C;
    font-size: 0.8rem;
    font-weight: 500;
    padding-left: 1rem;
}

.InputFCotiNum2 input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNum2 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.Info {
    background-color: #4b7637;
    color: #ffff;
    border: none;
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 20px;
}

.Info:hover{
    background-color: #6ca031;
}
.imgInfo{
    width: 100%;
}
.WrapperFormPreCoti{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.LabelFCoti4{
    width: 70%;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight:700;
    margin: 1rem auto;
    text-align: center;
}
.LabelFCoti5{
    width: 100%;
    padding-left: 2rem;
    font-size: 1rem;
    font-weight: 500;
    margin: 1rem auto;
    text-align: left;
}


/* The slider itself */
.InputFCRange {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 70%; /* Full-width */
  height: 0.5rem; /* Specified height */
  background: #d4d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  margin: 1rem auto;
  margin-bottom: 2rem;
}

/* Mouse-over effects */
.InputFCRange:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.InputFCRange::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 1.5rem; /* Set a specific slider handle width */
  height: 1.5rem; /* Slider handle height */
  background: rgb(75, 118, 55); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.InputFCRange::-moz-range-thumb { 
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgb(75, 118, 55); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.BlockRFPreCoti{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-bottom: 1rem;
}
.LabelFCoti6{
  width: 65%;
  text-align: left ;
  color: #295B85;
  font-size: 16px;
  font-weight: 500;
}

.ConteInputFCRange{
  width: 70%;
  text-align: center;
  position: relative;
  margin: auto;
  z-index: 1;
}
.MinFCRange{
  position: absolute;
  z-index: 5;
  left: -0.5rem;
  top: 2rem;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #1d5481;
}
.MaxFCRange{
  position: absolute;
  z-index: 5;
  right: -0.5rem;
  top: 2rem;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #1d5481;
}

.InputFCotiNumPorcent{
  width: 65%;
  height: 2.5rem;
  margin: 1rem auto;
  border: none;
  background-color: #EBEBEB;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.InputFCotiNumPorcent input{
  -webkit-appearance: none;
  appearance: none;
  min-height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #EBEBEB;
  width: 100%;
}
.InputFCotiNumPorcent input::-webkit-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumPorcent input:-ms-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumPorcent input::placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumPorcent:after{
  position: absolute;
  z-index: 3;
  right: 0;
  content: '( % )';
  color: #9C9C9C;
  font-size: 0.8rem;
  font-weight: 500;
  padding-right: 1rem;
}

.InputFCotiNumPorcent input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNumPorcent input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
margin: 0; 
}

.InputFCotiNumMoneda{
  width: 65%;
  height: 2.5rem;
  margin: 1rem auto;
  border: none;
  background-color: #EBEBEB;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.InputFCotiNumMoneda input{
  -webkit-appearance: none;
  appearance: none;
  min-height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #EBEBEB;
  width: 100%;
}
.InputFCotiNumMoneda input::-webkit-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumMoneda input:-ms-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumMoneda input::placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumMoneda:after{
  position: absolute;
  z-index: 3;
  right: 0;
  content: '$';
  color: #9C9C9C;
  font-size: 0.8rem;
  font-weight: 500;
  padding-right: 1rem;
}

.InputFCotiNumMoneda input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNumMoneda input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
margin: 0; 
}
/*SIgno Watts*/
.InputFCotiWatts{
  width: 65%;
  height: 2.5rem;
  margin: 1rem auto;
  border: none;
  background-color: #EBEBEB;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.InputFCotiWatts input{
  -webkit-appearance: none;
  appearance: none;
  min-height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #EBEBEB;
  width: 100%;
}
.InputFCotiWatts input::-webkit-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiWatts input:-ms-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiWatts input::placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiWatts:after{
  position: absolute;
  z-index: 3;
  right: 0;
  content: 'W';
  color: #9C9C9C;
  font-size: 0.8rem;
  font-weight: 500;
  padding-right: 1rem;
}
.WrapperFormFinan{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.WrapperFormContact{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.WrapperFormRegistro{
    width: 100%;
}

.TitleFRegistro{
    color: #295B85;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    padding-right: 4rem;
    padding-bottom: 0.7rem;
    margin: 3rem 4rem;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.ContainerFRegistro{
    width: 65%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.LabelFRegisto{
    width: 75%;
    margin: 1.5rem 0;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}
.InputFRegisto{
    width: 100%;
    height: 2.5rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
}

.InputFRegisto::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFRegisto[type='date']{
    color: #9C9C9C;
    font-family: 'Roboto', sans-serif;
    padding-right: 1rem;
}
.BlockRFRegistro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 37.5%;
}
.LabelRFRegistro{
    width: 50%;
    color: #295B85;
    font-size: 16px;
    font-weight: 500;
}
.RadioFRegistro{
    -webkit-appearance: none;
    appearance: none;
  
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background-color: #EBEBEB;
    border: 5px solid #EBEBEB;
    transition: 0.2s all linear;
    margin-right: 5px;
}
.RadioFRegistro:checked {
    background-color: #1d5481;
}
  
.FormRegistro{
    text-align: center;
}
.ButtonFRegistro{
    margin: 3rem auto;
    color: #ffff;
    background-color: #1d5481;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonFRegistro:hover{
    background-color: #22b601;
}
@media (min-width:900px) {
    .WrapperFormRegistro{
        width: 65%;
    }
}

@media (min-width:550px) {
    .InputFRegisto{
        width: 75%;
    }
    .LabelFRegisto{
        width: 25%;
        text-align: right;
        margin: 1.5rem 0;
    }
}


.ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}

.modal-fondo {
    background-color:rgba(38, 38, 38, 0.158);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 50;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}


.WrapperMAlert{
    width: 230px;
    height: 230px;
    background-color: #ffff;
    margin: auto;
    padding: 2rem;
    padding-bottom: 0.2rem; 
    position: relative;
    text-align: center;
    border-radius: 2rem;
}



.ImgModal{
    width: 95%;
    margin: auto;

}

.TextModal{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 2rem;
}
.WrapperBannerFinCoti{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;   
}
.TitleBannerFinCoti{
    padding-top: 6rem;
    font-size: 1.2rem;
    width: 80%;
    font-weight: 500;
    color: #295B85;
}
.WrapperFormContact{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.WrapperFormRegistro{
    width: 100%;
}

.TitleFRegistro{
    color: #295B85;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    padding-right: 4rem;
    padding-bottom: 0.7rem;
    margin: 3rem 4rem;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.ContainerFRegistro{
    width: 65%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.LabelFRegisto{
    width: 75%;
    margin: 1.5rem 0;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}
.InputFRegisto{
    width: 100%;
    height: 2.5rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
}

.InputFRegisto::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFRegisto[type='date']{
    color: #9C9C9C;
    font-family: 'Roboto', sans-serif;
    padding-right: 1rem;
}
.BlockRFRegistro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 37.5%;
}
.LabelRFRegistro{
    width: 50%;
    color: #295B85;
    font-size: 16px;
    font-weight: 500;
}
.RadioFRegistro{
    -webkit-appearance: none;
    appearance: none;
  
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background-color: #EBEBEB;
    border: 5px solid #EBEBEB;
    transition: 0.2s all linear;
    margin-right: 5px;
}
.RadioFRegistro:checked {
    background-color: #1d5481;
}
  
.FormRegistro{
    text-align: center;
}
.ButtonFRegistro{
    margin: 3rem auto;
    color: #ffff;
    background-color: #1d5481;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonFRegistro:hover{
    background-color: #22b601;
}
@media (min-width:900px) {
    .WrapperFormRegistro{
        width: 65%;
    }
}

@media (min-width:550px) {
    .InputFRegisto{
        width: 75%;
    }
    .LabelFRegisto{
        width: 25%;
        text-align: right;
        margin: 1.5rem 0;
    }
}

.Cotizador{
    background-color: white;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
}

@media(max-width: 900px){
    .Cotizador{
        display: block;
    }
}
.WrapperFormEnergia{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.LabelFCoti3{
    width: 60%;
    padding-left: 1rem;
    font-size: 16px;
    font-weight: 500;
    margin: 1.5rem auto;
    display: flex;
    justify-content: left;
}

.BlockRFEnergia{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.InputFCotiNum1{
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
}
.InputFCotiNum1 input{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
}
.InputFCotiNum1 input::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1 input:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1 input::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1:after{
    position: absolute;
    z-index: 3;
    right: 0;
    content: 'KW';
    color: #9C9C9C;
    font-size: 0.8rem;
    font-weight: 500;
    padding-right: 1rem;
}

.InputFCotiNum1 input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNum1 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.InputFCotiNum2{
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
}
.InputFCotiNum2 input{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 2rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
}
.InputFCotiNum2 input::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2 input:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2 input::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2:after{
    position: absolute;
    z-index: 3;
    left: 0;
    content: '$';
    color: #9C9C9C;
    font-size: 0.8rem;
    font-weight: 500;
    padding-left: 1rem;
}

.InputFCotiNum2 input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNum2 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.WrapperFormPreCoti{
  margin: auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.LabelFCoti4{
  width: 70%;
  padding-left: 1rem;
  font-size: 0.8rem;
  font-weight: 400;
  margin: 1rem auto;
  text-align: center;
}
.LabelFCoti5{
  width: 100%;
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem auto;
  text-align: left;
}


/* The slider itself */
.InputFCRange {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 70%; /* Full-width */
  height: 0.5rem; /* Specified height */
  background: #d4d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  margin: 1rem auto;
  margin-bottom: 2rem;
}

/* Mouse-over effects */
.InputFCRange:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.InputFCRange::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 1.5rem; /* Set a specific slider handle width */
  height: 1.5rem; /* Slider handle height */
  background: rgb(75, 118, 55); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.InputFCRange::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgb(75, 118, 55); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.BlockRFPreCoti{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-bottom: 1rem;
}
.LabelFCoti6{
  width: 65%;
  text-align: left ;
  color: #295B85;
  font-size: 16px;
  font-weight: 500;
}

.ConteInputFCRange{
  width: 70%;
  text-align: center;
  position: relative;
  margin: auto;
  z-index: 1;
}
.MinFCRange{
  position: absolute;
  z-index: 5;
  left: -0.5rem;
  top: 2rem;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #1d5481;
}
.MaxFCRange{
  position: absolute;
  z-index: 5;
  right: -0.5rem;
  top: 2rem;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #1d5481;
}

.InputFCotiNumPorcent{
  width: 65%;
  height: 2.5rem;
  margin: 1rem auto;
  border: none;
  background-color: #EBEBEB;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.InputFCotiNumPorcent input{
  -webkit-appearance: none;
  appearance: none;
  min-height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #EBEBEB;
  width: 100%;
}
.InputFCotiNumPorcent input::-webkit-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumPorcent input:-ms-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumPorcent input::placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumPorcent:after{
  position: absolute;
  z-index: 3;
  right: 0;
  content: '( % )';
  color: #9C9C9C;
  font-size: 0.8rem;
  font-weight: 500;
  padding-right: 1rem;
}

.InputFCotiNumPorcent input[type=number]::-webkit-inner-spin-button,
.InputFCotiNumPorcent input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.InputFCotiNumMoneda{
  width: 65%;
  height: 2.5rem;
  margin: 1rem auto;
  border: none;
  background-color: #EBEBEB;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.InputFCotiNumMoneda input{
  -webkit-appearance: none;
  appearance: none;
  min-height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #EBEBEB;
  width: 100%;
}
.InputFCotiNumMoneda input::-webkit-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumMoneda input:-ms-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumMoneda input::placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiNumMoneda:after{
  position: absolute;
  z-index: 3;
  right: 0;
  content: '$';
  color: #9C9C9C;
  font-size: 0.8rem;
  font-weight: 500;
  padding-right: 1rem;
}

.InputFCotiNumMoneda input[type=number]::-webkit-inner-spin-button,
.InputFCotiNumMoneda input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*SIgno Watts*/
.InputFCotiWatts{
  width: 65%;
  height: 2.5rem;
  margin: 1rem auto;
  border: none;
  background-color: #EBEBEB;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.InputFCotiWatts input{
  -webkit-appearance: none;
  appearance: none;
  min-height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #EBEBEB;
  width: 100%;
}
.InputFCotiWatts input::-webkit-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiWatts input:-ms-input-placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiWatts input::placeholder {
  color: #9C9C9C;
  font-size: 14px;
  font-weight: 300;
}
.InputFCotiWatts:after{
  position: absolute;
  z-index: 3;
  right: 0;
  content: 'W';
  color: #9C9C9C;
  font-size: 0.8rem;
  font-weight: 500;
  padding-right: 1rem;
}

.divChecked{
  width: 100%;
}

.divChecked input{
  background-color: #1d5481;
  width: 50%;
  text-align: center;
}

.divChecked input + label {
  line-height: 3em;
}
.divChecked input:checked + label {
  color: rgb(75, 118, 55);
}
.LabelChecked{
  font-size: 16px;
  font-weight: 500;
  width: 20rem;
}

.ValoresRecibos {
  margin-top: 10px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  justify-content: space-around;
}


.WrapperFormFinan{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.WrapperFormContact{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.WrapperFormRegistro{
    width: 100%;
}

.TitleFRegistro{
    color: #295B85;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    padding-right: 4rem;
    padding-bottom: 0.7rem;
    margin: 3rem 4rem;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.ContainerFRegistro{
    width: 65%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.LabelFRegisto{
    width: 75%;
    margin: 1.5rem 0;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}
.InputFRegisto{
    width: 100%;
    height: 2.5rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
}

.InputFRegisto::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFRegisto[type='date']{
    color: #9C9C9C;
    font-family: 'Roboto', sans-serif;
    padding-right: 1rem;
}
.BlockRFRegistro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 37.5%;
}
.LabelRFRegistro{
    width: 50%;
    color: #295B85;
    font-size: 16px;
    font-weight: 500;
}
.RadioFRegistro{
    -webkit-appearance: none;
    appearance: none;
  
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background-color: #EBEBEB;
    border: 5px solid #EBEBEB;
    transition: 0.2s all linear;
    margin-right: 5px;
}
.RadioFRegistro:checked {
    background-color: #1d5481;
}
  
.FormRegistro{
    text-align: center;
}
.ButtonFRegistro{
    margin: 3rem auto;
    color: #ffff;
    background-color: #1d5481;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonFRegistro:hover{
    background-color: #22b601;
}
@media (min-width:900px) {
    .WrapperFormRegistro{
        width: 65%;
    }
}

@media (min-width:550px) {
    .InputFRegisto{
        width: 75%;
    }
    .LabelFRegisto{
        width: 25%;
        text-align: right;
        margin: 1.5rem 0;
    }
}

.WrapperBannerFinCoti{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;   
}
.TitleBannerFinCoti{
    padding-top: 6rem;
    font-size: 1.2rem;
    width: 80%;
    font-weight: 500;
    color: #295B85;
}
.WrapperFormContact{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.WrapperFormRegistro{
    width: 100%;
}

.TitleFRegistro{
    color: #295B85;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-bottom: 3px solid #22b601;
    padding-right: 4rem;
    padding-bottom: 0.7rem;
    margin: 3rem 4rem;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.ContainerFRegistro{
    width: 65%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.LabelFRegisto{
    width: 75%;
    margin: 1.5rem 0;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}
.InputFRegisto{
    width: 100%;
    height: 2.5rem;
    margin: auto;
    border: none;
    background-color: #EBEBEB;
    padding-left: 1rem;
}

.InputFRegisto::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}

.InputFRegisto::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFRegisto[type='date']{
    color: #9C9C9C;
    font-family: 'Roboto', sans-serif;
    padding-right: 1rem;
}
.BlockRFRegistro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 37.5%;
}
.LabelRFRegistro{
    width: 50%;
    color: #295B85;
    font-size: 16px;
    font-weight: 500;
}
.RadioFRegistro{
    -webkit-appearance: none;
    appearance: none;
  
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background-color: #EBEBEB;
    border: 5px solid #EBEBEB;
    transition: 0.2s all linear;
    margin-right: 5px;
}
.RadioFRegistro:checked {
    background-color: #1d5481;
}
  
.FormRegistro{
    text-align: center;
}
.ButtonFRegistro{
    margin: 3rem auto;
    color: #ffff;
    background-color: #1d5481;
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 1rem 6rem;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px; */
    transition: 0.3s;
}
.ButtonFRegistro:hover{
    background-color: #22b601;
}
@media (min-width:900px) {
    .WrapperFormRegistro{
        width: 65%;
    }
}

@media (min-width:550px) {
    .InputFRegisto{
        width: 75%;
    }
    .LabelFRegisto{
        width: 25%;
        text-align: right;
        margin: 1.5rem 0;
    }
}

.WrapperFormEnergia{
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.LabelFCoti3{
    width: 60%;
    padding-left: 1rem;
    font-size: 16px;
    font-weight: 500;
    margin: 1.5rem auto;
    display: flex;
    justify-content: left;
}

.BlockRFEnergia{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.InputFCotiNum1{
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
}
.InputFCotiNum1 input{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
}
.InputFCotiNum1 input::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1 input:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1 input::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum1:after{
    position: absolute;
    z-index: 3;
    right: 0;
    content: 'KW';
    color: #9C9C9C;
    font-size: 0.8rem;
    font-weight: 500;
    padding-right: 1rem;
}

.InputFCotiNum1 input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNum1 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.InputFCotiNum2{
    width: 65%;
    height: 2.5rem;
    margin: 1rem auto;
    border: none;
    background-color: #EBEBEB;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
}
.InputFCotiNum2 input{
    -webkit-appearance: none;
    appearance: none;
    min-height: 100%;
    border: none;
    padding-left: 2rem;
    padding-right: 1rem;
    background-color: #EBEBEB;
    width: 100%;
}
.InputFCotiNum2 input::-webkit-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2 input:-ms-input-placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2 input::placeholder {
    color: #9C9C9C;
    font-size: 14px;
    font-weight: 300;
}
.InputFCotiNum2:after{
    position: absolute;
    z-index: 3;
    left: 0;
    content: '$';
    color: #9C9C9C;
    font-size: 0.8rem;
    font-weight: 500;
    padding-left: 1rem;
}

.InputFCotiNum2 input[type=number]::-webkit-inner-spin-button, 
.InputFCotiNum2 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

