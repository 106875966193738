.MainWrapper{
    position: relative;
    width: 100vw;
}

.SliderMain{
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    overflow: hidden;
}
.MainContainer{
    min-width: 100%;
    height:800px;
    position: relative;
}

.BannerMain{
    width: 100%;
    height: 800px;
    object-fit: cover;
    position: absolute;
    z-index: 5;
}

.InfoMain{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
    background-color: rgba(0, 0, 0, 0.3);
}
.ContainerArrowM{
    position: absolute;
    z-index: 7;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 2rem;
}
.SliderArrowMB{
    cursor: pointer;
    color:  #8bc540;
    border: none;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    width: 2rem;
    height: 2rem;
    border-radius: 30px;
    margin: auto 0;
}
.TextMain{
    width: 90%;
    text-align: center; 
}

.TitleMain{
    color: white;
    font-size: 38px;
    font-weight: 700;
    padding: 2rem 1.5rem;
}
.ButtonMain{
    background-color: #8bc540 ;
    color: #fff ;
    width:fit-content;
    margin: 2rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    text-align:center;
    cursor: pointer;
    text-decoration: none;
}
#CertiArrowLeftMain{
    left: 0;
}
#CertiArrowRightMain{
    right: 0;
}